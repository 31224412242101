// ============================================================================
// == @NOTICE: DO NOT delete this function, both hooks for create and update ==
// == must return same properties and functions to not break components      ==
// == functionality                                                          ==
// ============================================================================

import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import webCore from '@happylife-a/web-core';
import { useLocalizedUrlBuilder } from '../../../hooks';
import { useDraftProduct } from '../contexts/DraftProductContext';
import { useProductCreation } from '../contexts/ProductCreationContext';

const apiDraftProduct = webCore.coreFactories.draftProductUseCase;

function useCommonWizardParams(isSingle, components, productCreationInfo) {
  const { urlDraftSingle, urlDraftVariational } = useProductCreation();
  const buildLocalizedUrl = useLocalizedUrlBuilder(true);

  const form = useForm({
    defaultValues: {
      loadedFormFieldIds: [],
    },
  });

  useEffect(() => {
    if (!productCreationInfo) {
      return;
    }

    form.reset(productCreationInfo.data);
  }, [productCreationInfo]);

  const [index, setIndex] = useState(0);

  const redirectToDraft = useCallback(
    (id) => {
      const url = isSingle ? urlDraftSingle : urlDraftVariational;
      const finalUrl = buildLocalizedUrl(null, url.replace(':id', id));

      window.history.replaceState({}, 'Create', finalUrl);
    },
    [isSingle, urlDraftSingle, urlDraftVariational]
  );

  const activeComponent = components[index];
  return {
    form: form,
    activeComponent: activeComponent,
    index: index,
    setIndex: setIndex,
    redirectToDraft: redirectToDraft,
  };
}

function useWizardStepperFormCreate(
  sellerId,
  components,
  isSingle,
  draftProductId
) {
  const { form, activeComponent, index, setIndex, redirectToDraft } =
    useCommonWizardParams(isSingle, components);

  const { setDraftProductId } = useDraftProduct();

  const { mutate: createDraftProduct } =
    webCore.coreHooks.draftProduct.useCreateDraftProduct();

  const { mutate: updateDraftProduct } =
    webCore.coreHooks.draftProduct.useUpdateDraftProduct(draftProductId);

  const { mutate: deleteDraftProduct } =
    webCore.coreHooks.draftProduct.useDeleteDraftProduct({
      id: draftProductId,
      params: { sellerId: sellerId },
    });

  const discardChanges = useCallback(() => {
    deleteDraftProduct(draftProductId);
  }, [draftProductId]);

  const syncChanges = useCallback(() => {
    const categoryId = form.getValues()?.GeneralInfo?.categoryId;
    if (!categoryId) {
      return;
    }

    if (!draftProductId) {
      createDraftProduct(
        {
          isSingle: isSingle,
          data: form.getValues(),
          sellerId: sellerId,
        },
        {
          onSuccess: (draftProduct) => {
            setDraftProductId(draftProduct.id);
            redirectToDraft(draftProduct.id);
          },
        }
      );
    } else {
      updateDraftProduct({
        data: form.getValues(),
        sellerId: sellerId,
      });
    }
  }, [draftProductId]);

  // when draftProductId provided then need to load draft product data and
  // change index to corresponding page.
  useEffect(() => {
    if (!draftProductId) {
      return;
    }

    apiDraftProduct
      .getDraftProduct(draftProductId, { sellerId: sellerId })
      .then((draftProduct) => {
        if (draftProduct.draftStep === 'general') {
          setIndex(0);
        } else if (draftProduct.draftStep === 'details') {
          setIndex(1);
        } else {
          setIndex(2);
        }
        if (draftProduct.data?.MainDetails?._filesUploading) {
          draftProduct.data.MainDetails._filesUploading = false;
        }

        const draftObject = {
          ...draftProduct.data,
        };

        form.reset(draftObject);
      });
  }, [draftProductId]);

  useEffect(() => {
    if (index === 0) {
      return;
    }

    syncChanges();
  }, [draftProductId, index]);

  const newResult = JSON.stringify(form.watch());
  const refThreshold = useRef();

  useEffect(() => {
    clearTimeout(refThreshold.current);
    refThreshold.current = setTimeout(() => {
      syncChanges();
    }, 300);
  }, [draftProductId, newResult]);

  return {
    syncChanges: syncChanges,
    discardChanges: discardChanges,
    form: form,
    activeComponent: activeComponent,
    index: index,
    setIndex: setIndex,
  };
}

function useWizardStepperFormUpdate(components, isSingle, productCreationInfo) {
  const { form, activeComponent, index, setIndex } = useCommonWizardParams(
    isSingle,
    components,
    productCreationInfo
  );

  const discardChanges = useCallback(() => {
    // ...
  }, []);

  const syncChanges = useCallback(() => {
    // ...
  }, []);

  return {
    syncChanges: syncChanges,
    discardChanges: discardChanges,
    form: form,
    activeComponent: activeComponent,
    index: index,
    setIndex: setIndex,
  };
}

export function useWizardStepperForm(
  sellerId,
  components,
  isSingle,
  productCreationInfo,
  draftProductId
) {
  const formFunctionalCreate = useWizardStepperFormCreate(
    sellerId,
    components,
    isSingle,
    draftProductId
  );

  const formFunctionalUpdate = useWizardStepperFormUpdate(
    components,
    isSingle,
    productCreationInfo
  );

  return productCreationInfo ? formFunctionalUpdate : formFunctionalCreate;
}
