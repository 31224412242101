import { dataTypes } from './constants';
import { typedFields } from './definition';

export function parse(field, value) {
  switch (typedFields[field]) {
    case dataTypes.Required.Number:
      return Number(value);
    case dataTypes.Required.String:
      return value;
    case dataTypes.Required.Boolean:
      return [true, 'true'].includes(value);
    case dataTypes.Required.Array:
    case dataTypes.Required.Object:
      return JSON.parse(value);

    case dataTypes.Optional.Number:
      return value === null ? null : Number(value);
    case dataTypes.Optional.String:
      return value === null ? null : value;
    case dataTypes.Optional.Boolean:
      return value === null ? null : [true, 'true'].includes(value);

    case dataTypes.Optional.Array:
    case dataTypes.Optional.Object:
      return value === null ? null : JSON.parse(value);
  }

  throw new Error(
    'Unknown data type for value: ' +
      JSON.stringify({ field: field, value: value })
  );
}

export function stringify(field, value) {
  switch (typedFields[field]) {
    case dataTypes.Required.Number:
    case dataTypes.Optional.Number:
      return value === null ? null : Number(value);

    case dataTypes.Required.String:
    case dataTypes.Optional.String:
      return value === null ? null : !value ? null : value.toString();

    case dataTypes.Required.Boolean:
    case dataTypes.Optional.Boolean:
      return value === null ? null : !!value;

    case dataTypes.Required.Array:
    case dataTypes.Optional.Array:
    case dataTypes.Required.Object:
    case dataTypes.Optional.Object:
      return value === null ? null : JSON.stringify(value);
  }

  throw new Error(
    'Unknown data type for value: ' +
      JSON.stringify({ field: field, value: value })
  );
}
