import React from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Text } from '@chakra-ui/react';

function ProductNameAndDetails({
  selectable,
  productDetail,
  product,
  displayProductInfo,
}) {
  const { locale } = webCore.contexts.useTranslation();

  return (
    <>
      <Box height={12}>
        <Text
          variant={{ base: 'body-reg-lg', md: 'sub-head-reg' }}
          noOfLines={2}
        >
          <Text
            as="span"
            cursor="pointer"
            onClick={!selectable ? displayProductInfo : undefined}
          >
            {productDetail?.title?.[locale] || product?.title?.[locale]}
          </Text>
        </Text>
      </Box>
      <Flex>
        <Text
          variant={{
            base: 'body-reg-sm-bold',
            sm: 'sub-head-bold',
            md: 'h3-bold',
          }}
          mt={2}
        >
          {utils.helpers.currency.beautify(
            productDetail?.discountedPrice || productDetail?.price
          )}
        </Text>
        {!!productDetail?.discountedPrice && (
          <Text
            variant={{
              base: 'body-reg-sm',
              sm: 'sub-head-bold',
              md: 'sub-head-bold',
            }}
            mt={{ base: 2, md: 4 }}
            ml={1}
            opacity={0.5}
            textDecorationLine="line-through"
          >
            {utils.helpers.currency.beautify(productDetail?.price)}
          </Text>
        )}
      </Flex>
    </>
  );
}

export default ProductNameAndDetails;
