import http, { API_CORE } from '../http';

export default function ProductService() {
  const getDraftProducts = async (params) => {
    const response = await http(API_CORE).get('/draft-products', {
      params: params,
    });
    return response.data;
  };

  const getDraftProduct = async (id, params = {}) => {
    const response = await http(API_CORE).get(`/draft-products/${id}`, {
      params: params,
    });
    return response.data.draftProduct;
  };

  const createDraftProduct = async (input) => {
    const response = await http(API_CORE).post('/draft-products', input);
    return response.data.draftProduct;
  };

  const updateDraftProduct = async (id, input) => {
    const response = await http(API_CORE).put(`/draft-products/${id}`, input);
    return response.data.draftProduct;
  };

  const deleteDraftProduct = async (id, params = {}) => {
    const response = await http(API_CORE).delete(`/draft-products/${id}`, {
      params: params,
    });
    return response;
  };

  return {
    getDraftProducts: getDraftProducts,
    getDraftProduct: getDraftProduct,
    createDraftProduct: createDraftProduct,
    updateDraftProduct: updateDraftProduct,
    deleteDraftProduct: deleteDraftProduct,
  };
}
