import { useCallback, useState, useEffect } from 'react';

function emptyFunc() {}

function useValidCounter({
  count: initialCount,
  minValue,
  maxValue,
  onChange,
}) {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const canIncrement = count < maxValue;
  const canDecrement = count > minValue;
  const hasValidRange = minValue <= maxValue;

  const updateCount = useCallback(
    (value, isIncrement) => {
      if (!value || typeof onChange !== 'function') {
        return;
      }

      if (typeof value !== 'number') {
        value = 1;
      }

      const newValue = isIncrement ? count + value : count - value;

      if (
        (isIncrement && newValue <= maxValue) ||
        (!isIncrement && newValue >= minValue)
      ) {
        setCount(newValue);
        onChange(newValue);
      }
    },
    [count, minValue, maxValue, onChange]
  );

  const increment = useCallback(
    (value = 1) => updateCount(value, true),
    [updateCount]
  );

  const decrement = useCallback(
    (value = 1) => updateCount(value, false),
    [updateCount]
  );

  const setCustomCount = useCallback(
    (value) => {
      const numericValue = parseInt(value, 10);

      if (
        !isNaN(numericValue) &&
        numericValue >= minValue &&
        numericValue <= maxValue
      ) {
        setCount(numericValue);
        onChange(numericValue);
      }
    },
    [minValue, maxValue, onChange]
  );

  return {
    count: count,
    hasValidRange: hasValidRange,
    canIncrement: canIncrement,
    canDecrement: canDecrement,
    increment: canIncrement ? increment : emptyFunc,
    decrement: canDecrement ? decrement : emptyFunc,
    setCustomCount: setCustomCount,
  };
}

export default useValidCounter;
