import React, { createContext, useContext, useMemo } from 'react';
import appBranding from '@happylife-a/branding';
import Fonts from './Fonts';
import { exporting } from './theme';

const UiContext = createContext({});

export const useUi = () => useContext(UiContext);

const brandEnv = appBranding.configs.environments;
const themeFields = appBranding.fields.brand.UI.Theming.Theme.Override;

const theming = {
  override: {
    gradients: brandEnv.getField(themeFields.Gradients),
    colors: brandEnv.getField(themeFields.Colors),
    styles: brandEnv.getField(themeFields.Styles),
    shadows: brandEnv.getField(themeFields.Shadows),
    fontSizes: brandEnv.getField(themeFields.FontSizes),
    space: brandEnv.getField(themeFields.Space),
    sizes: brandEnv.getField(themeFields.Sizes),
  },
};

export function UiProvider({ provider: ChakraProvider, children }) {
  const theme = useMemo(() => exporting.buildTheme(theming || {}), []);
  if (!theme) {
    return children;
  }

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <UiContext.Provider value={{}}>{children}</UiContext.Provider>
    </ChakraProvider>
  );
}

export default UiProvider;
