/* eslint-disable react/no-unknown-property */
import React, { useCallback, useMemo } from 'react';
import appBranding from '@happylife-a/branding';
import GoogleMapReact from 'google-map-react';
import { useGoogleApi } from '../../contexts';
import { theme } from '../../theme';

function Marker({ lat, lng }) {
  const markerSize = 40;
  const markerIcon = useMemo(
    () =>
      'data:image/svg+xml;charset=UTF-8,' +
      encodeURIComponent(
        `
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="${theme.colors.primary[500]}" />
            <circle cx="20.001" cy="20.0001" r="9.47368" fill="white" />
          </svg>
        `
      ),
    []
  );

  return (
    <img
      src={markerIcon}
      alt="Marker"
      lat={lat}
      lng={lng}
      style={{
        height: `${markerSize}px`,
        width: `${markerSize}px`,
        marginTop: `-${markerSize / 2}px`,
        marginLeft: `-${markerSize / 2}px`,
      }}
    />
  );
}

function Map({ coordinates, onCoordinatesChoose, onMapLoaded }) {
  const brandEnv = appBranding.configs.environments;
  const map = appBranding.fields.brand.CountryParam.Map;

  const initialMapPosition = {
    defaultZoom: brandEnv.getField(map.InitialZoom),
    defaultCenter: {
      lat: brandEnv.getField(map.InitialCenter.Latitude),
      lng: brandEnv.getField(map.InitialCenter.Longitude),
    },
  };

  const { apiKey, libraries } = useGoogleApi();

  const onClick = useCallback(
    (event) => {
      if (typeof onCoordinatesChoose !== 'function') {
        return;
      }

      onCoordinatesChoose({
        lat: event.lat,
        lng: event.lng,
      });
    },
    [onCoordinatesChoose]
  );

  const createMapOptions = () => ({
    fullscreenControl: false,
  });

  const googleMapReactPropsRest = {};

  if (coordinates?.lat && coordinates?.lng) {
    googleMapReactPropsRest.center = {};
    googleMapReactPropsRest.center.lat = coordinates.lat;
    googleMapReactPropsRest.center.lng = coordinates.lng;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: apiKey,
        libraries: libraries,
      }}
      {...initialMapPosition}
      debounced={true}
      options={createMapOptions}
      onGoogleApiLoaded={onMapLoaded}
      onClick={onClick}
      {...googleMapReactPropsRest}
    >
      {coordinates && <Marker lat={coordinates.lat} lng={coordinates.lng} />}
    </GoogleMapReact>
  );
}

export default React.memo(Map);
