function deepMergeImmutable(objSource, objDestination) {
  if (typeof objSource !== 'object' || objSource === null) {
    return objSource;
  }

  Object.entries(objSource).forEach(([key, value]) => {
    const immutableValue = Array.isArray(value)
      ? value.map((item) => deepMergeImmutable(item, {}))
      : deepMergeImmutable(value, {});

    try {
      objDestination[key] = immutableValue;
    } catch {
      // @INFO: [readonly] this will happen only if we try to reassign same object.
    }
  });

  return Object.freeze(objDestination);
}

let defaultTenantIdentifier = null;
function setDefaultTenantIdentifier(tenantIdentifier) {
  defaultTenantIdentifier = tenantIdentifier;
}

let absolutePath = null;
function setAbsolutePath(path) {
  absolutePath = path;
}

let mergedConfigs = {};
function initiate({ environments = {}, policies = {}, translations = {} }) {
  mergedConfigs = {
    environments: environments,
    policies: policies,
    translations: translations,
  };
}

function configureTenantIdentifier(
  tenantIdentifier,
  { environments = {}, policies = {}, translations = {} }
) {
  mergedConfigs[tenantIdentifier] = deepMergeImmutable(
    mergedConfigs[tenantIdentifier] || {},
    {
      environments: environments,
      policies: policies,
      translations: translations,
    }
  );
}

function createGetterAssets() {
  const getAssetFor = (tenantIdentifier, path) => {
    return `/.app-branding/${tenantIdentifier}/${path}`;
  };
  const getAsset = (path) => {
    return getAssetFor(defaultTenantIdentifier, path);
  };
  const getAbsoluteAssetFor = (tenantIdentifier, path) => {
    return `${absolutePath}/.app-branding/${tenantIdentifier}/${path}`;
  };
  const getAbsoluteAsset = (path) => {
    return getAbsoluteAssetFor(defaultTenantIdentifier, path);
  };

  return {
    getAssetFor: getAssetFor,
    getAsset: getAsset,
    getAbsoluteAssetFor: getAbsoluteAssetFor,
    getAbsoluteAsset: getAbsoluteAsset,
  };
}
function createGetterEnvironments() {
  const getFieldFor = (tenantIdentifier, field) => {
    return mergedConfigs?.environments?.[tenantIdentifier]?.[field];
  };
  const getField = (field) => {
    return getFieldFor(defaultTenantIdentifier, field);
  };

  return {
    getFieldFor: getFieldFor,
    getField: getField,
  };
}
function createGetterNamespace(namespace) {
  const getDataFor = (tenantIdentifier) => {
    return mergedConfigs?.[namespace]?.[tenantIdentifier];
  };
  const getData = () => {
    return getDataFor(defaultTenantIdentifier);
  };

  return {
    getDataFor: getDataFor,
    getData: getData,
  };
}

const getterForAssets = createGetterAssets();
const getterForEnvironments = createGetterEnvironments();
const getterForPolicies = createGetterNamespace('policies');
const getterForTranslations = createGetterNamespace('translations');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setDefaultTenantIdentifier: setDefaultTenantIdentifier,
  setAbsolutePath: setAbsolutePath,
  initiate: initiate,
  configureTenantIdentifier: configureTenantIdentifier,
  assets: getterForAssets,
  environments: getterForEnvironments,
  policies: getterForPolicies,
  translations: getterForTranslations,
};
