/* eslint-disable no-console */

export function empty() {
  console.log();
}
export function divider() {
  console.log('='.repeat(80));
}

let prefix = '';
export function setPrefix(newPrefix) {
  prefix = newPrefix;
}

function formatArgument(arg) {
  if (typeof arg === 'object') {
    try {
      if (arg instanceof Error && arg.stack) {
        return arg.stack;
      }

      return JSON.stringify(arg, null, 2);
    } catch {
      // ...
    }
  }

  return arg;
}

/**
 * @returns {import('./logging-types').LoggingInterface}
 */
function buildLogger(emoji, callFunc) {
  const execute = function (caller, message, ...args) {
    const text = !emoji ? message : `${emoji}  ${message}`;
    const content = !prefix ? text : `${prefix} | ${text}`;

    caller(content, args.map(formatArgument).join('\n'));
  };

  const loggerMethod = (...args) => execute(callFunc, ...args);
  loggerMethod.trace = (...args) => execute(console.trace, ...args);

  return loggerMethod;
}

export const log = buildLogger(null, console.log);
export const debug = buildLogger('🔍', console.debug);
export const info = buildLogger('ℹ️', console.info);
export const success = buildLogger('✅', console.log);
export const warning = buildLogger('⚠️', console.warn);
export const error = buildLogger('❌', console.error);
export const fatal = buildLogger('💣', console.error);
export const critical = buildLogger('🔥', console.error);

/**
 * @param {Error} errorInstance
 */
export function exception(errorInstance, details = null) {
  let message = `Exception [${errorInstance.message}]`;
  if (/sequelize/gim.test(errorInstance.name)) {
    let messageWithSql = `${errorInstance.name}, ${errorInstance.message}`;
    if (errorInstance.sql) {
      messageWithSql += `>>> SQL: ${errorInstance.sql} <<<`;
    }

    message = `Database Exception [${messageWithSql}]:`;
  }

  if (errorInstance.stack) {
    if (details) {
      fatal(message, errorInstance, details);
    } else {
      fatal(message, errorInstance);
    }
  } else {
    if (details) {
      fatal.trace(message, errorInstance, details);
    } else {
      fatal.trace(message, errorInstance);
    }
  }
}
