import { useEffect } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { onDelete, onUpdate, onCreate } from '../../helpers/reactQuery';
import {
  REACT_QUERY_GET_PRODUCTS_QUERY_KEY,
  REACT_QUERY_GET_PRODUCT_QUERY_KEY,
  REACT_QUERY_GET_PRODUCT_VARIANTS_QUERY_KEY,
  REACT_QUERY_GET_PRODUCT_SELLER_MY_PRODUCTS_QUERY_KEY,
  REACT_QUERY_GET_PRODUCT_FILTER_PRODUCTS_QUERY_KEY,
  REACT_QUERY_GET_SIMILAR_PRODUCTS_QUERY_KEY,
  REACT_QUERY_GET_MULTI_SIMILAR_PRODUCTS_QUERY_KEY,
  REACT_QUERY_GET_REVIEWS_KEY,
  REACT_QUERY_GET_PENDING_PRODUCT_QUERY_KEY,
  REACT_QUERY_GET_PRODUCTS_DETAILS_QUERY_KEY,
  REACT_QUERY_GET_PRODUCTS_RANDOM_DAILY_DETAILS_QUERY_KEY,
  REACT_QUERY_GET_PRODUCTS_RANDOM_DAILY_DETAILS_INFINITE_QUERY_KEY,
  REACT_QUERY_GET_SELLER_PENDING_PRODUCTS_KEY,
  REACT_QUERY_GET_PRODUCTS_DETAILS_INFINITE_QUERY_KEY,
  REACT_QUERY_GET_MAX_DISCOUNT_PERCENTAGE_KEY,
} from '../constants';
import productUseCase from '../factories/product';

export const useGetProducts = (variables, options = {}) => {
  const queryClient = useQueryClient();
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCTS_QUERY_KEY, variables],
    () => productUseCase.getProducts(variables),
    options
  );

  useEffect(() => {
    if (query?.data) {
      query.data.products.forEach((product) =>
        queryClient.setQueryData(
          [REACT_QUERY_GET_PRODUCT_QUERY_KEY, product.id],
          product
        )
      );
    }
  }, [query]);

  return query;
};

export const useGetProductsDetails = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCTS_DETAILS_QUERY_KEY, variables],
    () => productUseCase.getProductsDetails(variables),
    options
  );

  return query;
};

export const useGetProductsRandomDailyDetails = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCTS_RANDOM_DAILY_DETAILS_QUERY_KEY, variables],
    () => productUseCase.getProductsRandomDailyDetails(variables),
    options
  );

  return query;
};

export const useGetProductsRandomDailyDetailsInfinite = (
  variables,
  options = {}
) => {
  const query = useInfiniteQuery(
    [
      REACT_QUERY_GET_PRODUCTS_RANDOM_DAILY_DETAILS_INFINITE_QUERY_KEY,
      variables,
    ],
    ({ pageParam }) => {
      if (pageParam === null) {
        return { productsDetails: [], meta: {} };
      }

      return productUseCase.getProductsRandomDailyDetails({
        ...variables,
        offset: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const { offset, limit, total } = lastPage.meta;

        const nextOffset = offset + limit;

        if (nextOffset < total) {
          return nextOffset;
        }

        return null;
      },
      ...options,
    }
  );

  return query;
};

export const useGetProductsDetailsInfinite = (variables, options = {}) => {
  const query = useInfiniteQuery(
    [REACT_QUERY_GET_PRODUCTS_DETAILS_INFINITE_QUERY_KEY, variables],
    ({ pageParam }) => {
      if (pageParam === null) {
        return { productsDetails: [], meta: {} };
      }

      return productUseCase.getProductsDetails({
        ...variables,
        offset: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const { offset, limit, total } = lastPage.meta;

        const nextOffset = offset + limit;

        if (nextOffset < total) {
          return nextOffset;
        }

        return null;
      },
      ...options,
    }
  );

  return query;
};

export const useGetReviews = (productId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_REVIEWS_KEY, productId],
    () => productUseCase.getReviews(productId),
    options
  );

  return query;
};

export const useGetSimilarProducts = (productId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SIMILAR_PRODUCTS_QUERY_KEY, productId],
    () => productUseCase.getSimilarProducts(productId),
    options
  );

  return query;
};

export const useGetMultiSimilarProducts = (productIds, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_MULTI_SIMILAR_PRODUCTS_QUERY_KEY, productIds],
    () => productUseCase.getMultiSimilarProducts(productIds),
    options
  );

  return query;
};

export const useGetProduct = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCT_QUERY_KEY, id],
    () => productUseCase.getProduct(id),
    options
  );

  return query;
};

export const useUploadMultipleImages = () =>
  useMutation(productUseCase.uploadMultipleImages);

export const useUploadReviewImage = () =>
  useMutation(productUseCase.uploadReviewImage);

export const useCreateProduct = () => {
  const mutation = useMutation(productUseCase.createProduct);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData([REACT_QUERY_GET_PRODUCTS_QUERY_KEY], (data) =>
        onCreate(data, mutation.data)
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useCreateProductDetailValue = () => {
  const mutation = useMutation(productUseCase.createProductDetailValue);
  return mutation;
};

export const useCreateProductDetailVariationalValues = () => {
  const mutation = useMutation(
    productUseCase.createProductDetailVariationalValues
  );
  return mutation;
};

export const useGetProductVariationValues = (id) => {
  const query = useQuery([REACT_QUERY_GET_PRODUCT_VARIANTS_QUERY_KEY, id], () =>
    productUseCase.getProductVariationValues(id)
  );
  return query;
};

export const useCreateProductImages = () => {
  const mutation = useMutation(productUseCase.createProductImages);
  return mutation;
};

export const useUpdateProduct = () => {
  const mutation = useMutation(productUseCase.updateProduct);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData([REACT_QUERY_GET_PRODUCTS_QUERY_KEY], (data) =>
        onUpdate(data, mutation)
      );
      queryClient.setQueriesData(
        [REACT_QUERY_GET_PRODUCT_QUERY_KEY, mutation.data.id],
        mutation.data
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useDeleteProduct = () => {
  const mutation = useMutation(productUseCase.deleteProduct);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData([REACT_QUERY_GET_PRODUCTS_QUERY_KEY], (data) =>
        onDelete(data, mutation)
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useResubmitDeclinedProduct = () => {
  return useMutation(productUseCase.resubmitDeclinedProduct);
};

export const useUploadImage = () => useMutation(productUseCase.uploadImage);

export const useAssociationProductImagesToDetails = () => {
  const mutation = useMutation(
    productUseCase.associationProductImagesToDetails
  );
  return mutation;
};

export const usePublishProduct = () => {
  const mutation = useMutation(productUseCase.publishProduct);
  return mutation;
};

export const useGetSellerMyProducts = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCT_SELLER_MY_PRODUCTS_QUERY_KEY, variables],
    () => productUseCase.getSellerMyProducts(variables),
    options
  );

  return query;
};

export const useUpdateProductDetail = (productId) => {
  const queryClient = useQueryClient();

  return useMutation(async (inputs) => {
    queryClient.removeQueries([REACT_QUERY_GET_PRODUCT_QUERY_KEY]);

    await productUseCase.updateProductDetail(productId, inputs.id, {
      ...inputs,
      id: undefined,
    });
  });
};

export const useAddProductReview = (productId, productDetailId) => {
  const mutation = useMutation((data) =>
    productUseCase.createProductReview(productId, productDetailId, data)
  );
  return mutation;
};

export const useFilterProducts = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCT_FILTER_PRODUCTS_QUERY_KEY, variables],
    () => productUseCase.filterProducts(variables),
    options
  );

  return query;
};

export const useFilterProductsInfinite = (variables, options = {}) => {
  const query = useInfiniteQuery(
    [REACT_QUERY_GET_PRODUCT_FILTER_PRODUCTS_QUERY_KEY, variables],
    ({ pageParam = 0 }) => {
      if (pageParam === null) {
        return { productsDetails: [], meta: {} };
      }

      return productUseCase.filterProducts({
        ...variables,
        offset: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const { offset, limit, total } = lastPage.meta;

        const nextOffset = offset + limit;

        if (nextOffset < total) {
          return nextOffset;
        }

        return null;
      },
      ...options,
    }
  );

  return query;
};

export const useGetPendingProducts = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PENDING_PRODUCT_QUERY_KEY, variables],
    () => productUseCase.getPendingProducts(variables),
    options
  );

  return query;
};

export const useApproveProduct = () => {
  const mutation = useMutation(productUseCase.approveProduct);

  return mutation;
};

export const useDenyProduct = (productId) => {
  const mutation = useMutation((data) =>
    productUseCase.denyProduct(productId, data)
  );
  return mutation;
};

export const useGetSellerPendingProducts = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SELLER_PENDING_PRODUCTS_KEY],
    () => productUseCase.getSellerPendingProducts(variables),
    options
  );

  return query;
};

export const useDeleteSingleProduct = (productId) => {
  const mutation = useMutation(() =>
    productUseCase.deleteSingleProduct(productId)
  );

  return mutation;
};

export const useDeleteProductsByIds = () => {
  const mutation = useMutation(({ ids }) =>
    productUseCase.deleteProductsByIds(ids)
  );

  return mutation;
};

export const useUpdateProductDetailPrice = (productId, productDetailId) => {
  const mutation = useMutation((data) =>
    productUseCase.updateProductDetailPrice(
      productId,
      productDetailId,
      data.price
    )
  );

  return mutation;
};

export const useUpdateProductDetailsPrices = (productId) => {
  const mutation = useMutation((data) =>
    productUseCase.updateProductDetailsPrices(productId, data)
  );

  return mutation;
};

export const useUpdateProductDetailValues = (productId, productDetailId) => {
  const mutation = useMutation((data) =>
    productUseCase.updateProductDetailValues(productId, productDetailId, data)
  );

  return mutation;
};

export const useGetMaxDiscountPercentage = (options) => {
  const query = useQuery(
    [REACT_QUERY_GET_MAX_DISCOUNT_PERCENTAGE_KEY],
    () => productUseCase.getMaxDiscountPercentage(),
    options
  );

  return query;
};
