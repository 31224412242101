import React, { useMemo } from 'react';
import { Global } from '@emotion/react';
import appBranding from '@happylife-a/branding';

function buildFont(fontName) {
  const weightNumbers = [100, 200, 300, 400, 500, 600, 700, 800, 900];
  const weights = [
    ...weightNumbers.map((weightNumber) => `0,${weightNumber}`),
    ...weightNumbers.map((weightNumber) => `1,${weightNumber}`),
  ].join(';');

  const url = `https://fonts.googleapis.com/css2?display=swap&family=${fontName}:ital,wght@${weights}`;
  return `@import url('${url}')`;
}

export default function Fonts() {
  const fonts = useMemo(() => {
    return appBranding.configs.environments
      .getField(appBranding.fields.brand.UI.Theming.Fonts)
      ?.map((font) => buildFont(font.replace(/\s/g, '+')))
      ?.join(';\n');
  }, []);

  if (!fonts) {
    return null;
  }

  return <Global styles={fonts} />;
}
