import React, { useMemo } from 'react';
import {
  ApisProvider,
  ExceptionHandlerProvider,
  FirebaseProvider,
  MessagingProvider,
  UserProvider,
  AppConfigsProvider,
} from '../../contexts';
import ConfigurationProvider from '../ConfigurationProvider';
import ReactQueryProvider from '../ReactQueryProvider';
import configureBrandingEnvironment from './configure';

function HappyLifeCoreProvider({
  children,
  firebase = null,
  localStorage = null,
  errorComponent = null,
  allowGuestUser = false,
}) {
  const { fileStorage, firebaseRealtimeDatabase } = useMemo(
    () => configureBrandingEnvironment(),
    []
  );

  return (
    <ExceptionHandlerProvider FallbackComponent={errorComponent}>
      <ConfigurationProvider
        fileStorage={fileStorage}
        localStorage={localStorage}
      >
        <ApisProvider>
          <ReactQueryProvider>
            <AppConfigsProvider>
              <FirebaseProvider
                {...firebaseRealtimeDatabase}
                projectId={firebase?.projectId}
                apiKey={firebase?.apiKey}
                messagingSenderId={firebase?.messagingSenderId}
                appId={firebase?.appId}
                measurementId={firebase?.measurementId}
                analyticsEnabled={firebase?.analyticsEnabled}
              >
                <UserProvider allowGuestUser={allowGuestUser}>
                  <MessagingProvider>
                    <>{children}</>
                  </MessagingProvider>
                </UserProvider>
              </FirebaseProvider>
            </AppConfigsProvider>
          </ReactQueryProvider>
        </ApisProvider>
      </ConfigurationProvider>
    </ExceptionHandlerProvider>
  );
}

export default HappyLifeCoreProvider;
