import nameBrand from './named/brand';
import nameEnv from './named/env';

import { dataTypes, validateDataType } from './constants';
import { typedFields, accessibility } from './definition';
import * as helpers from './helpers';
import * as features from './features';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataTypes: dataTypes,
  validateDataType: validateDataType,
  features: features,
  helpers: helpers,
  fields: {
    typed: typedFields,
    accessibility: accessibility,
    named: {
      env: nameEnv,
      brand: nameBrand,
    },
  },
};
