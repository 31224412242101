function buildType(type, required) {
  return `DataType::${required ? 'Required' : 'Optional'}::${type}`;
}

export const dataTypes = {
  Required: {
    String: buildType('String', true),
    Number: buildType('Number', true),
    Boolean: buildType('Boolean', true),
    Array: buildType('Array', true),
    Object: buildType('Object', true),
  },
  Optional: {
    String: buildType('String', false),
    Number: buildType('Number', false),
    Boolean: buildType('Boolean', false),
    Array: buildType('Array', false),
    Object: buildType('Object', false),
  },
};

export function validateDataType(dataType, value) {
  const optionalTypes = Object.values(dataTypes.Optional);
  if ([null, undefined].includes(value)) {
    return optionalTypes.includes(dataType);
  }

  const opt = dataTypes.Optional;
  const req = dataTypes.Required;

  if ([opt.Boolean, req.Boolean].includes(dataType)) {
    return typeof value === 'boolean';
  }
  if ([opt.String, req.String].includes(dataType)) {
    return typeof value === 'string';
  }
  if ([opt.Number, req.Number].includes(dataType)) {
    return typeof value === 'number';
  }
  if ([opt.Array, req.Array].includes(dataType)) {
    return Array.isArray(value);
  }
  if ([opt.Object, req.Object].includes(dataType)) {
    return typeof value === 'object' && !!value;
  }

  return false;
}
