/* eslint-disable import/order */
import appBranding from '@happylife-a/branding';
import webApp from '@happylife-a/web-app';
/* eslint-enable import/order */

import tenantEnvironments from '../.app-branding/tenant-environments.json';
import tenantPolicies from '../.app-branding/tenant-policies.json';
import tenantTranslations from '../.app-branding/tenant-translations.json';

appBranding.configs.setDefaultTenantIdentifier(
  import.meta.env.VITE_API_TENANT_IDENTIFIER
);

appBranding.configs.initiate({
  environments: tenantEnvironments,
  policies: tenantPolicies,
  translations: tenantTranslations,
});

webApp.theme.initWithBranding();
webApp.configure('MaaS');

export { appBranding };
