function buildHeaderKey(name) {
  const updated = name.replace(
    /[a-z0-9][A-Z]/g,
    (part) => part.charAt(0) + '-' + part.charAt(1)
  );

  return updated.toLowerCase();
}

function buildCustomHeaderKey(name) {
  return buildHeaderKey(`X-${name}`);
}

export const HttpClientHeaderEnum = Object.freeze({
  ClientInformation: buildCustomHeaderKey('Client-Information'),
  ApiTenantIdentifier: buildCustomHeaderKey('ApiTenantIdentifier'),
});

export const HttpServerHeaderEnum = Object.freeze({
  // standard headers:
  ETag: buildHeaderKey('ETag'),
  CacheControl: buildHeaderKey('Cache-Control'),
  LastModified: buildHeaderKey('Last-Modified'),

  // custom headers:
  ApiName: buildCustomHeaderKey('API-Name'),
  ApiVersion: buildCustomHeaderKey('API-Version'),
  RequestStart: buildCustomHeaderKey('Request-Start'),
  RequestEnd: buildCustomHeaderKey('Request-End'),
  RequestDuration: buildCustomHeaderKey('Request-Duration'),
  RequestID: buildCustomHeaderKey('Request-Id'),
  ResponseCacheStart: buildCustomHeaderKey('Response-Cache-Time-Start'),
  ResponseCacheEnd: buildCustomHeaderKey('Response-Cache-Time-End'),
  ResponseCacheAgeDuration: buildCustomHeaderKey('Response-Cache-Duration'),
  ResponseCacheKey: buildCustomHeaderKey('Response-Cache-Key'),
  ResponseFrom: buildCustomHeaderKey('Response-From'),
  ImageMimeType: buildCustomHeaderKey('ImageMimeType'),
  InternalApiToken: buildCustomHeaderKey('InternalApiToken'),
});
