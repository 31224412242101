import appBranding from '@happylife-a/branding';
import * as localization from '@happylife-a/localization';
import utils from '@happylife-a/utils';

export default function configure() {
  const brandEnv = appBranding.configs.environments;

  // aws s3
  const awsS3Fields = appBranding.fields.env.AWS.S3;
  const fileStorage = {
    bucket: brandEnv.getField(awsS3Fields.BucketName),
    region: brandEnv.getField(awsS3Fields.Region),
    domain: brandEnv.getField(awsS3Fields.Domain),
  };

  // firebase realtime database
  const firebaseRealtimeDatabaseFields =
    appBranding.fields.env.Firebase.RealtimeDatabase;
  const firebaseRealtimeDatabase = {
    database: brandEnv.getField(firebaseRealtimeDatabaseFields.Name),
    region: brandEnv.getField(firebaseRealtimeDatabaseFields.Region),
  };

  // default currency
  utils.helpers.currency.setDefaultCurrency(
    brandEnv.getField(appBranding.fields.brand.CountryParam.Currency)
  );

  // translations and locales
  const localeFields = appBranding.fields.brand.CountryParam.Locale;
  localization.setLocalesAndDefault(
    [...(brandEnv.getField(localeFields.List) || [])],
    brandEnv.getField(localeFields.Default)
  );

  const translationOverrides = appBranding.configs.translations.getData();
  localization.setOverrideContent(translationOverrides);

  // return configured objects
  return {
    fileStorage: fileStorage,
    firebaseRealtimeDatabase: firebaseRealtimeDatabase,
  };
}
