/* prettier: { "printWidth": 200 } */

import { dataTypes } from './constants';
import env from './named/env';
import brand from './named/brand';

const allFields = {
  // #region environments
  // aws
  [env.AWS.S3.Region]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.AWS.S3.BucketName]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.AWS.S3.InvoiceBucketName]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.AWS.S3.Domain]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.AWS.Cognito.UseNicknameAsIdentifier]: {
    isPublic: false,
    type: dataTypes.Required.Boolean,
  },
  [env.AWS.Cognito.Region]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.Cognito.UserPoolID]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.Cognito.ClientID]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.Cognito.ClientSecret]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.S3.ClientBucketName]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.SES.Region]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.SES.TemplatePrefix]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.SES.SenderName]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.AWS.SES.SenderEmail]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },

  // firebase
  [env.Firebase.RealtimeDatabase.Region]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.Firebase.RealtimeDatabase.Name]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.Firebase.RemoteConfig.ProjectID]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.RemoteConfig.ClientEmail]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.RemoteConfig.PrivateKey]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.CloudMessaging.ProjectID]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.CloudMessaging.ProjectKeyID]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.CloudMessaging.ClientID]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.CloudMessaging.ClientEmail]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },
  [env.Firebase.CloudMessaging.PrivateKey]: {
    isPublic: false,
    type: dataTypes.Required.String,
  },

  // map api
  [env.Map.GoogleMap.Client.ApiKey]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [env.Map.YandexMap.Client.ApiKey]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },

  // payments
  [env.Payment.AmeriaBank.ClientID]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Payment.AmeriaBank.Username]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Payment.AmeriaBank.Password]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // delivery
  [env.Delivery.HayPost.API.Username]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.HayPost.API.Password]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.HayPost.API.CustomerID]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.MegaMove.API.Username]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.MegaMove.API.Password]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.MegaMove.Vendor.TokenSignature]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.MegaMove.Vendor.Username]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.MegaMove.Vendor.Password]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.ZZLogistics.API.User.FirstName]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.ZZLogistics.API.User.LastName]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.ZZLogistics.API.User.Email]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.ZZLogistics.API.User.Password]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.ZZLogistics.Vendor.AccessToken]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.Yandex.API.AuthToken]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Delivery.Yandex.API.CallbackSecret]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // product source
  [env.ProductSource.Ali1688.AppKey]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.ProductSource.Ali1688.AppSecret]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.ProductSource.Ali1688.AccessToken]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // map
  [env.Map.GoogleMap.API.ApiKey]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Map.YandexMap.API.GeocoderHttp.ApiKey]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.Map.YandexMap.API.GeoSuggest.ApiKey]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // ai
  [env.AI.OpenAI.ApiKey]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [env.AI.OpenAI.Model]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // legal info
  [env.LegalInfo.DaData.AuthToken]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // platforms settings
  [env.PlatformSettings.ProductPriceIncreasePercent]: {
    isPublic: false,
    type: dataTypes.Required.Number,
  },
  [env.PlatformSettings.YandexExpressDeliveryAdditionalCost]: {
    isPublic: false,
    type: dataTypes.Required.Number,
  },

  // branch.io
  [env.BranchIO.LiveKey]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [env.BranchIO.TestKey]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  // #endregion

  // #region branding
  // app branding
  [brand.AppBranding.ThemeColor]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.AppBranding.AppName]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.AppBranding.MobileApp.BundleIdentifier]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.AppBranding.MobileApp.IosApp.Name]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.AppBranding.MobileApp.IosApp.ID]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.AppBranding.MobileApp.DeepLinking.Protocols]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.AppBranding.MobileApp.DeepLinking.Domains]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },

  // country params
  [brand.CountryParam.InitialCountry]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.CountryParam.Currency]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.CountryParam.PhoneCode]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.CountryParam.PhoneValidationPattern]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.CountryParam.Locale.Default]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.CountryParam.Locale.List]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.CountryParam.Map.Provider]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.CountryParam.Map.CountryRestrictions]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.CountryParam.Map.InitialZoom]: {
    isPublic: true,
    type: dataTypes.Required.Number,
  },
  [brand.CountryParam.Map.InitialCenter.Latitude]: {
    isPublic: true,
    type: dataTypes.Required.Number,
  },
  [brand.CountryParam.Map.InitialCenter.Longitude]: {
    isPublic: true,
    type: dataTypes.Required.Number,
  },

  // contacts
  [brand.Contacts.Addresses]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.Contacts.Phones]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.Contacts.Emails]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.Contacts.NoReplyEmail]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },

  // links
  [brand.Links.MobileApp.GooglePlay]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.MobileApp.AppleAppStore]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.MobileApp.AppDownloadLink]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.Social.Facebook]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.Social.Instagram]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.Social.LinkedIn]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.Social.YouTube]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.Social.Telegram]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.Links.Booking.Calendly]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },

  // feature-flag
  [brand.FeatureFlag.Chat]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.PhysicalCustomer]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.LegalCustomer]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.LegalCustomerGrant]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.DownloadAppModal]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.Collection]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.CollectionShareEnabled]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.ContactUs]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.Collaboration]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.AdminPushNotifications]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.AdminBadges]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.DeliveryEnabled]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.ShoppingCartCustomAmount]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.ExpressEnabled]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.ProductShare]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.ShowProfileFaq]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.ShowHelpCenter]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.FeatureFlag.SellerSinglePageAbility]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },

  // ui
  [brand.UI.Theming.Fonts]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.UI.Theming.Theme.Color.Primary]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.UI.Theming.Theme.Color.Secondary]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.UI.Theming.Theme.Color.Tertiary]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.UI.Theming.Theme.Override.Gradients]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Theming.Theme.Override.Colors]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Theming.Theme.Override.Styles]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Theming.Theme.Override.Shadows]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Theming.Theme.Override.FontSizes]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Theming.Theme.Override.Space]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Theming.Theme.Override.Sizes]: {
    isPublic: true,
    type: dataTypes.Required.Object,
  },
  [brand.UI.Settings.MobileApp.SplashScreen.Background]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.UI.Settings.MobileApp.SplashScreen.Foreground]: {
    isPublic: true,
    type: dataTypes.Required.String,
  },
  [brand.UI.Settings.Corporation.DesktopLargeContainer]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.DesktopWhatToSellStyleClassName]: {
    isPublic: true,
    type: dataTypes.Optional.String,
  },
  [brand.UI.Settings.Corporation.ShowTopCompanies]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers.AllInOne]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers.BusinessVerification]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers.BuiltInMarketingTools]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers.CustomerSupport]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers.ReachRegionalAudience]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers
    .DataDrivenInsightsPersonalization]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.BenefitsForSellers.EasyAndIntuitiveDashboard]:
    {
      isPublic: true,
      type: dataTypes.Required.Boolean,
    },
  [brand.UI.Settings.Corporation.WeOfferFeatures.BusinessVerification]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.WeOfferFeatures.BuiltInMarketingTools]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.WeOfferFeatures.CustomerSupport]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },
  [brand.UI.Settings.Corporation.WeOfferFeatures.LogisticsAndLegalSupport]: {
    isPublic: true,
    type: dataTypes.Required.Boolean,
  },

  // payment
  [brand.Payment.CustomerMinOrderValue]: {
    isPublic: true,
    type: dataTypes.Required.Number,
  },
  [brand.Payment.Providers]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },

  // order
  [brand.Order.AutoRejectHours]: {
    isPublic: false,
    type: dataTypes.Required.Number,
  },
  [brand.Order.VATPercent]: {
    isPublic: false,
    type: dataTypes.Required.Number,
  },

  // legal info
  [brand.LegalInfo.Provider]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },

  // product source
  [brand.ProductSource.Providers]: {
    isPublic: false,
    type: dataTypes.Required.Array,
  },

  // delivery
  [brand.Delivery.Providers.Enabled]: {
    isPublic: true,
    type: dataTypes.Required.Array,
  },
  [brand.Delivery.Providers.Defaults.Pickup]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [brand.Delivery.Providers.Defaults.Delivery]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [brand.Delivery.Providers.Defaults.Express]: {
    isPublic: false,
    type: dataTypes.Optional.String,
  },
  [brand.Delivery.Configs]: {
    isPublic: false,
    type: dataTypes.Required.Object,
  },
  // #endregion
};

export const typedFields = Object.entries(allFields).reduce(
  (acc, [key, { type }]) => {
    acc[key] = type;
    return acc;
  },
  {}
);

export const accessibility = Object.entries(allFields).reduce(
  (acc, [key, { isPublic }]) => {
    acc[isPublic ? 'public' : 'private'].push(key);
    return acc;
  },
  { public: [], private: [] }
);
