import enums from '@happylife-a/enums';
import appBranding from '@happylife-a/branding';

export function shouldShowPaymentMethods(tenantIdentifier = null) {
  const fieldName = appBranding.fields.brand.Payment.Providers;
  const brandEnv = appBranding.configs.environments;

  const paymentProviders = tenantIdentifier
    ? brandEnv.getFieldFor(tenantIdentifier, fieldName)
    : brandEnv.getField(fieldName);

  return (
    paymentProviders.length !== 1 ||
    paymentProviders[0] !== enums.payment.PaymentProviderEnum.Invoice
  );
}
