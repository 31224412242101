import brandFields from '../fields/named/brand';
import configs from '../configs';

/**
 * @param {keyof typeof brandFields.FeatureFlag} name
 */
export function isEnabled(name) {
  const value = configs.environments.getField(brandFields.FeatureFlag[name]);
  return value === true;
}

/**
 * @param {keyof typeof brandFields.FeatureFlag} name
 */
export function isEnabledFor(tenantIdentifier, name) {
  const value = configs.environments.getFieldFor(
    tenantIdentifier,
    brandFields.FeatureFlag[name]
  );
  return value === true;
}
