import webCore from '@happylife-a/web-core';

const MainLayout = webCore.helpers.dynamic(() => import('~layouts/Main'));

const HomePage = webCore.helpers.dynamic(() => import('~pages/index'));
const NotFound = webCore.helpers.dynamic(() => import('~pages/404'));
const AllSolutions = webCore.helpers.dynamic(
  () => import('~pages/home/OurSolutions/viewAll/index')
);
const TermsAndConditions = webCore.helpers.dynamic(
  () => import('~pages/terms-and-conditions/index')
);
const PrivacyPolicy = webCore.helpers.dynamic(
  () => import('~pages/privacy-policy/index')
);

const screens = [
  {
    pathname: '/',
    screen: HomePage,
    layout: MainLayout,
  },

  // #########################################################
  // #######       ALWAYS KEEP 404 IN LAST PLACE       #######
  // #########################################################

  // the not-found route must be end of the list.
  {
    pathname: '*',
    screen: NotFound,
    layout: MainLayout,
  },

  {
    pathname: '/all-solutions',
    screen: AllSolutions,
    layout: MainLayout,
  },
  {
    pathname: '/privacy-policy',
    screen: PrivacyPolicy,
    layout: MainLayout,
  },
  {
    pathname: '/terms-and-conditions',
    screen: TermsAndConditions,
    layout: MainLayout,
  },
];

export default screens;
