import React from 'react';
import webCore from '@happylife-a/web-core';
import { Link, Text } from '@chakra-ui/react';

export default function Ali1688Link({ ali1688Id }) {
  const { t } = webCore.contexts.useTranslation();

  if (!ali1688Id) {
    return null;
  }

  return (
    <Link
      color="blue.500"
      href={`https://detail.1688.com/offer/${ali1688Id}.html?offerId=${ali1688Id}`}
      target="_blank"
    >
      <Text variant="body-reg-lg">{t('Go to 1688')}</Text>
    </Link>
  );
}
