import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   DraftProductService: ReturnType<import('../../services/DraftProductService').default>,
 * }} param0
 */
export default function productUseCase({ DraftProductService }) {
  const getDraftProducts = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return DraftProductService.getDraftProducts(serviceVariables);
  };

  const getDraftProduct = (id, params) =>
    DraftProductService.getDraftProduct(id, params);

  const createDraftProduct = (input) =>
    DraftProductService.createDraftProduct(input);

  const updateDraftProduct = (id, input) =>
    DraftProductService.updateDraftProduct(id, input);

  const deleteDraftProduct = (id, params) =>
    DraftProductService.deleteDraftProduct(id, params);

  return {
    getDraftProducts: getDraftProducts,
    getDraftProduct: getDraftProduct,
    createDraftProduct: createDraftProduct,
    updateDraftProduct: updateDraftProduct,
    deleteDraftProduct: deleteDraftProduct,
  };
}
