import React from 'react';
import { translations, locales, LOCALE_EN } from '@happylife-a/localization';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { ChakraProvider } from '@chakra-ui/react';
import { PlanProvider } from '~contexts/PlanContext';
import modals from './modals';
import guestRoutes from './routes/guest';
import privateRoutes from './routes/private';
import publicRoutes from './routes/public';

const routeList = webApp.routing.buildMultiLangRoutes(locales, {
  guest: guestRoutes,
  private: privateRoutes,
  public: publicRoutes,
});

export default function App({ RouterComponent, ...props }) {
  return (
    <webCore.providers.HappyLifeCoreProvider
      localStorage={{
        encryptable: false, // @TODO: enable for production mode only
        secretKey: import.meta.env.VITE_SECURE_STORAGE_SECRET,
      }}
      errorComponent={(errorProps) => (
        <webApp.UiProvider provider={ChakraProvider}>
          <webApp.screens.Whoops {...errorProps} />
        </webApp.UiProvider>
      )}
      allowGuestUser={false}
    >
      <webApp.UiProvider provider={ChakraProvider}>
        <webApp.providers.HappyLifeWebProvider
          modals={modals}
          initialLocale={LOCALE_EN}
          translations={translations}
          htmlColor={{
            start: webApp.theme.colors.white[50],
            end: webApp.theme.colors.primary[100],
          }}
          sentry={{
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: 'MAASLanding',
          }}
        >
          <webCore.contexts.FavoriteProvider>
            <webCore.contexts.ShoppingCartProvider>
              <PlanProvider>
                <RouterComponent {...props}>
                  <webApp.contexts.ModalProvider.ModalPortal />
                  <webApp.components.ScrollToTop />

                  <webApp.router.Routes>
                    {routeList.map((route, index) => (
                      <webApp.router.Route
                        key={`AppRouteItem-${route.accessLevel}-${index}-${route.pathname}`}
                        path={route.pathname}
                        element={
                          <webApp.routing.AppRouteItem
                            {...route}
                            loginUrl="/"
                          />
                        }
                      />
                    ))}
                  </webApp.router.Routes>
                </RouterComponent>
              </PlanProvider>
            </webCore.contexts.ShoppingCartProvider>
          </webCore.contexts.FavoriteProvider>
        </webApp.providers.HappyLifeWebProvider>
      </webApp.UiProvider>
    </webCore.providers.HappyLifeCoreProvider>
  );
}
