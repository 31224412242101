/* prettier: { "printWidth": 200 } */

const branding = {
  AppBranding: {
    ThemeColor: 'brand::AppBranding::ThemeColor',
    AppName: 'brand::AppBranding::AppName',
    MobileApp: {
      BundleIdentifier: 'brand::AppBranding::MobileApp::BundleIdentifier',
      IosApp: {
        Name: 'brand::AppBranding::MobileApp::IosApp::Name',
        ID: 'brand::AppBranding::MobileApp::IosApp::ID',
      },
      DeepLinking: {
        Protocols: 'brand::AppBranding::MobileApp::DeepLinking::Protocols',
        Domains: 'brand::AppBranding::MobileApp::DeepLinking::Domains',
      },
    },
  },
  UI: {
    Theming: {
      Fonts: 'brand::UI::Theming::Fonts',
      Theme: {
        Color: {
          Primary: 'brand::UI::Theming::Theme::Color::Primary',
          Secondary: 'brand::UI::Theming::Theme::Color::Secondary',
          Tertiary: 'brand::UI::Theming::Theme::Color::Tertiary',
        },
        Override: {
          Gradients: 'brand::UI::Theming::Theme::Override::Gradients',
          Colors: 'brand::UI::Theming::Theme::Override::Colors',
          Styles: 'brand::UI::Theming::Theme::Override::Styles',
          Shadows: 'brand::UI::Theming::Theme::Override::Shadows',
          FontSizes: 'brand::UI::Theming::Theme::Override::FontSizes',
          Space: 'brand::UI::Theming::Theme::Override::Space',
          Sizes: 'brand::UI::Theming::Theme::Override::Sizes',
        },
      },
    },
    Settings: {
      MobileApp: {
        SplashScreen: {
          Background:
            'brand::UI::Settings::MobileApp::SplashScreen::Background',
          Foreground:
            'brand::UI::Settings::MobileApp::SplashScreen::Foreground',
        },
      },
      Corporation: {
        DesktopLargeContainer:
          'brand::UI::Settings::Corporation::DesktopLargeContainer',
        DesktopWhatToSellStyleClassName:
          'brand::UI::Settings::Corporation::DesktopWhatToSellStyleClassName',
        ShowTopCompanies: 'brand::UI::Settings::Corporation::ShowTopCompanies',
        BenefitsForSellers: {
          AllInOne:
            'brand::UI::Settings::Corporation::BenefitsForSellers::AllInOne',
          BusinessVerification:
            'brand::UI::Settings::Corporation::BenefitsForSellers::BusinessVerification',
          BuiltInMarketingTools:
            'brand::UI::Settings::Corporation::BenefitsForSellers::BuiltInMarketingTools',
          CustomerSupport:
            'brand::UI::Settings::Corporation::BenefitsForSellers::CustomerSupport',
          ReachRegionalAudience:
            'brand::UI::Settings::Corporation::BenefitsForSellers::ReachRegionalAudience',
          DataDrivenInsightsPersonalization:
            'brand::UI::Settings::Corporation::BenefitsForSellers::DataDrivenInsightsPersonalization',
          EasyAndIntuitiveDashboard:
            'brand::UI::Settings::Corporation::BenefitsForSellers::EasyAndIntuitiveDashboard',
        },
        WeOfferFeatures: {
          BusinessVerification:
            'brand::UI::Settings::Corporation::WeOfferFeatures::BusinessVerification',
          BuiltInMarketingTools:
            'brand::UI::Settings::Corporation::WeOfferFeatures::BuiltInMarketingTools',
          CustomerSupport:
            'brand::UI::Settings::Corporation::WeOfferFeatures::CustomerSupport',
          LogisticsAndLegalSupport:
            'brand::UI::Settings::Corporation::WeOfferFeatures::LogisticsAndLegalSupport',
        },
      },
    },
  },
  CountryParam: {
    InitialCountry: 'brand::CountryParam::InitialCountry',
    Currency: 'brand::CountryParam::Currency',
    PhoneCode: 'brand::CountryParam::PhoneCode',
    PhoneValidationPattern: 'brand::CountryParam::PhoneValidationPattern',
    Locale: {
      Default: 'brand::CountryParam::Locale::Default',
      List: 'brand::CountryParam::Locale::List',
    },
    Map: {
      Provider: 'brand::CountryParam::Map::Provider',
      CountryRestrictions: 'brand::CountryParam::Map::CountryRestrictions',
      InitialZoom: 'brand::CountryParam::Map::InitialZoom',
      InitialCenter: {
        Latitude: 'brand::CountryParam::Map::InitialCenter::Latitude',
        Longitude: 'brand::CountryParam::Map::InitialCenter::Longitude',
      },
    },
  },
  Payment: {
    CustomerMinOrderValue: 'brand::Payment::CustomerMinOrderValue',
    Providers: 'brand::Payment::Providers',
  },
  Order: {
    AutoRejectHours: 'brand::Order::AutoRejectHours',
    VATPercent: 'brand::Order::VATPercent',
  },
  LegalInfo: {
    Provider: 'brand::LegalInfo::Provider',
  },
  ProductSource: {
    Providers: 'brand::ProductSource::Providers',
  },
  Delivery: {
    Providers: {
      Enabled: 'brand::Delivery::Providers::Enabled',
      Defaults: {
        Pickup: 'brand::Delivery::Providers::Defaults::Pickup',
        Delivery: 'brand::Delivery::Providers::Defaults::Delivery',
        Express: 'brand::Delivery::Providers::Defaults::Express',
      },
    },
    Configs: 'brand::Delivery::Configs',
  },
  Contacts: {
    Addresses: 'brand::Contacts::Addresses',
    Phones: 'brand::Contacts::Phones',
    Emails: 'brand::Contacts::Email',
    NoReplyEmail: 'brand::Contacts::NoReplyEmail',
  },
  Links: {
    MobileApp: {
      GooglePlay: 'brand::Links::MobileApp::GooglePlay',
      AppleAppStore: 'brand::Links::MobileApp::AppleAppStore',
      AppDownloadLink: 'brand::Links::MobileApp::AppDownloadLink',
    },
    Social: {
      Facebook: 'brand::Links::Social::Facebook',
      Instagram: 'brand::Links::Social::Instagram',
      LinkedIn: 'brand::Links::Social::LinkedIn',
      YouTube: 'brand::Links::Social::YouTube',
      Telegram: 'brand::Links::Social::Telegram',
    },
    Booking: {
      Calendly: 'brand::Links::Booking::Calendly',
    },
  },
  FeatureFlag: {
    Chat: 'brand::FeatureFlag::Chat',
    PhysicalCustomer: 'brand::FeatureFlag::PhysicalCustomer',
    LegalCustomer: 'brand::FeatureFlag::LegalCustomer',
    LegalCustomerGrant: 'brand::FeatureFlag::LegalCustomerGrant',
    DownloadAppModal: 'brand::FeatureFlag::DownloadAppModal',
    Collection: 'brand::FeatureFlag::Collection',
    CollectionShareEnabled: 'brand::FeatureFlag::CollectionShareEnabled',
    ContactUs: 'brand::FeatureFlag::ContactUs',
    Collaboration: 'brand::FeatureFlag::Collaboration',
    AdminPushNotifications: 'brand::FeatureFlag::AdminPushNotifications',
    AdminBadges: 'brand::FeatureFlag::AdminBadges',
    DeliveryEnabled: 'brand::FeatureFlag::DeliveryEnabled',
    ShoppingCartCustomAmount: 'brand::FeatureFlag::ShoppingCartCustomAmount',
    ExpressEnabled: 'brand::FeatureFlag::ExpressEnabled',
    ProductShare: 'brand::FeatureFlag::ProductShare',
    ShowProfileFaq: 'brand::FeatureFlag::ShowProfileFaq',
    ShowHelpCenter: 'brand::FeatureFlag::ShowHelpCenter',
    SellerSinglePageAbility: 'brand::FeatureFlag::SellerSinglePageAbility',
  },
};

export default branding;
