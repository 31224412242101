import http, { API_CORE } from '../http';

export default function CategoryService() {
  const getCategoriesWithTotal = async (params) => {
    const response = await http(API_CORE).get('/categories/with-total', {
      params: params,
    });

    return response.data;
  };

  const searchCategories = async (params = {}) => {
    const response = await http(API_CORE).get('/categories', {
      params: params,
    });

    return response.data;
  };

  const getCategoryHierarchical = async (params) => {
    const response = await http(API_CORE).get('/categories/hierarchical', {
      params: params,
    });

    return response.data.categories;
  };

  const getCategoryHierarchicalForNavbar = async () => {
    const response = await http(API_CORE).get(
      '/categories/hierarchical/navbar'
    );
    return response.data.categories;
  };

  const getCategoryHierarchicalForHomepage = async () => {
    const response = await http(API_CORE).get(
      '/categories/hierarchical/home-page'
    );
    return response.data;
  };

  const getCategory = async (id) => {
    const response = await http(API_CORE).get(`/categories/${id}`);
    return response.data.category;
  };

  const getSubCategories = async (parentId) => {
    const response = await http(API_CORE).get(
      `/categories/${parentId}/hierarchical`
    );
    return response.data.category;
  };

  const createCategory = async (input) => {
    const response = await http(API_CORE).post('/categories', input);
    return response;
  };

  const updateCategory = async (input) => {
    const response = await http(API_CORE).put(`/categories/${input.id}`, input);
    return response;
  };

  const deleteCategory = async (id) => {
    const response = await http(API_CORE).delete(`/categories/${id}`);
    return response;
  };

  const getCategoryForm = async (id) => {
    const response = await http(API_CORE).get(`/categories/${id}/form-fields`);
    return response;
  };

  const createCategoryForm = async (id, input) => {
    const response = await http(API_CORE).post(
      `/categories/${id}/form-fields`,
      input
    );
    return response;
  };

  const updateCategoryForm = async (id, input) => {
    const response = await http(API_CORE).put(
      `/categories/${id}/form-fields/${input.id}`,
      input
    );

    return response;
  };

  const categoryFormBulkOperation = async (input) => {
    const response = await http(API_CORE).post(
      `/categories/${input.categoryId}/form-fields/bulk`,
      input.data
    );

    return response;
  };

  const getCategoryFormHierarchicalParent = async (id) => {
    const response = await http(API_CORE).get(
      `/categories/${id}/form-fields/hierarchical/parent`
    );

    return response.data.category;
  };

  const getCategoryFormHierarchicalChild = async (id) => {
    const response = await http(API_CORE).get(
      `/categories/${id}/form-fields/hierarchical/child`
    );

    return response.data.categories;
  };

  const getCategoryFormFieldsParent = async (id) => {
    const response = await http(API_CORE).get(
      `/categories/${id}/form-fields/parent`
    );
    return response.data.formFields;
  };

  const getCategoryFormFieldsChild = async (id) => {
    const response = await http(API_CORE).get(
      `/categories/${id}/form-fields/child`
    );
    return response.data.formFields;
  };

  const searchFormFieldsByName = async (name) => {
    const response = await http(API_CORE).get(
      `/categories/form-fields/values-by-name/${name}`
    );

    return response.data.formFields;
  };

  const createCategoryFormFieldValueBySeller = async (input) => {
    const response = await http(API_CORE).post(
      `/categories/${input.categoryId}/form-fields/${input.formFieldId}/values`,
      input.data
    );

    return response;
  };

  const approveFormFieldValue = async (input) => {
    const response = await http(API_CORE).patch(
      `/categories/form-fields/${input.formFieldId}/approve`,
      input.data
    );

    return response;
  };

  const declineFormFieldValue = async (input) => {
    const response = await http(API_CORE).patch(
      `/categories/form-fields/${input.formFieldId}/decline`,
      input.data
    );

    return response;
  };

  const getRandomCategories = async () => {
    const response = await http(API_CORE).get('/categories/random');
    return response.data;
  };

  const cloneCategory = async (params) => {
    const response = await http(API_CORE).post(
      `/categories/${params.categoryId}/clone`,
      params
    );

    return response.data;
  };

  const getCategoriesMaxDiscountPercentages = async () => {
    const response = await http(API_CORE).get(
      '/categories/max-discount-percentages'
    );
    return response.data;
  };

  return {
    getCategoriesWithTotal: getCategoriesWithTotal,
    getCategoryHierarchical: getCategoryHierarchical,
    getCategoryHierarchicalForNavbar: getCategoryHierarchicalForNavbar,
    getCategoryHierarchicalForHomepage: getCategoryHierarchicalForHomepage,
    searchCategories: searchCategories,
    getCategory: getCategory,
    getSubCategories: getSubCategories,
    createCategory: createCategory,
    updateCategory: updateCategory,
    deleteCategory: deleteCategory,
    getCategoryForm: getCategoryForm,
    createCategoryForm: createCategoryForm,
    updateCategoryForm: updateCategoryForm,
    categoryFormBulkOperation: categoryFormBulkOperation,
    getCategoryFormHierarchicalParent: getCategoryFormHierarchicalParent,
    getCategoryFormHierarchicalChild: getCategoryFormHierarchicalChild,
    getCategoryFormFieldsParent: getCategoryFormFieldsParent,
    getCategoryFormFieldsChild: getCategoryFormFieldsChild,
    searchFormFieldsByName: searchFormFieldsByName,
    createCategoryFormFieldValueBySeller: createCategoryFormFieldValueBySeller,
    approveFormFieldValue: approveFormFieldValue,
    declineFormFieldValue: declineFormFieldValue,
    getRandomCategories: getRandomCategories,
    cloneCategory: cloneCategory,
    getCategoriesMaxDiscountPercentages: getCategoriesMaxDiscountPercentages,
  };
}
