import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { onDelete, onUpdate, onCreate } from '../../helpers/reactQuery';
import {
  REACT_QUERY_GET_DRAFT_PRODUCTS_QUERY_KEY,
  REACT_QUERY_GET_DRAFT_PRODUCT_QUERY_KEY,
} from '../constants';
import draftProductUseCase from '../factories/draftProduct';

export const useGetDraftProducts = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_DRAFT_PRODUCTS_QUERY_KEY, variables],
    () => draftProductUseCase.getDraftProducts(variables),
    options
  );

  return query;
};

export const useGetDraftProduct = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_DRAFT_PRODUCT_QUERY_KEY, variables],
    () => draftProductUseCase.getDraftProduct(variables.id, variables.params),
    options
  );

  return query;
};

export const useCreateDraftProduct = () => {
  const mutation = useMutation(draftProductUseCase.createDraftProduct);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData(
        [REACT_QUERY_GET_DRAFT_PRODUCTS_QUERY_KEY],
        (data) => onCreate(data, mutation.data)
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useUpdateDraftProduct = (id) => {
  const mutation = useMutation((data) =>
    draftProductUseCase.updateDraftProduct(id, data)
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData(
        [REACT_QUERY_GET_DRAFT_PRODUCTS_QUERY_KEY],
        (data) => onUpdate(data, mutation)
      );
      queryClient.setQueriesData(
        [REACT_QUERY_GET_DRAFT_PRODUCT_QUERY_KEY, mutation.data.id],
        mutation.data
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useDeleteDraftProduct = (variables) => {
  const mutation = useMutation(() =>
    draftProductUseCase.deleteDraftProduct(variables.id, variables.params)
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData(
        [REACT_QUERY_GET_DRAFT_PRODUCTS_QUERY_KEY],
        (data) => onDelete(data, mutation)
      );
    }
  }, [mutation?.data]);

  return mutation;
};
