import React, { useContext, createContext, useEffect, useState } from 'react';
import appBranding from '@happylife-a/branding';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';

function withDoc(callback) {
  if (typeof document !== 'undefined') {
    callback(document);
  }
}

export const GoogleApiContext = createContext({
  isLoaded: false,
  apiKey: '',
  libraries: [],
  countries: [],
});

export const useGoogleApi = () => useContext(GoogleApiContext);

function appendScript(
  scriptElementId,
  { locale, apiKey, countries, libraries },
  onLoad
) {
  withDoc((doc) => {
    const params = {
      key: apiKey,
      libraries: libraries.join(','),
      region: countries.join(','),
      // loading: 'async',
      language: locale,
    };

    const queryParams = Object.keys(params)
      .reduce((acc, key) => {
        const value = encodeURIComponent(params[key]);
        return [...acc, `${key}=${value}`];
      }, [])
      .join('&');

    const url = `https://maps.googleapis.com/maps/api/js?${queryParams}`;
    const script = doc.createElement('script');
    script.setAttribute('id', scriptElementId);
    script.setAttribute('src', url);
    script.setAttribute('type', 'text/javascript');
    script.addEventListener('load', onLoad);
    doc.head.appendChild(script);
  });
}

function removeScript(scriptElementId) {
  withDoc((doc) => {
    const element = doc.getElementById(scriptElementId);
    if (element) {
      element.remove();
    }
  });
}

const defaultLibraries = ['places'];

export function GoogleApiProvider({
  children,
  apiKey,
  libraries = defaultLibraries,
  appendScriptOnLoad = true,
}) {
  const countries = appBranding.configs.environments.getField(
    appBranding.fields.brand.CountryParam.Map.CountryRestrictions
  );

  const { locale } = webCore.contexts.useTranslation();
  const [loaded, setLoaded] = useState(appendScriptOnLoad ? false : true);

  useEffect(() => {
    if (!appendScriptOnLoad) {
      return;
    }

    const scriptElementId = utils.helpers.random.uniqueId();
    appendScript(
      scriptElementId,
      {
        locale: locale,
        apiKey: apiKey,
        countries: countries,
        libraries: libraries,
      },
      () => {
        setLoaded(true);
      }
    );

    return () => {
      removeScript(scriptElementId);
    };
  }, [appendScriptOnLoad, locale, apiKey, libraries, countries]);

  if (!loaded) {
    return null;
  }

  return (
    <GoogleApiContext.Provider
      value={{
        isLoaded: loaded,
        apiKey: apiKey,
        libraries: libraries,
        countries: countries,
      }}
    >
      {children}
    </GoogleApiContext.Provider>
  );
}
