import React, { createContext, useContext, useState } from 'react';

export const STEP_CHOOSE = 'choose';
export const STEP_CONTACT = 'contact';

export const SoloShop = 'solo_shop';
export const Marketplace = 'marketplace';
export const Personalized = 'personalized';

const PlanContext = createContext({
  activeStep: STEP_CHOOSE,
  setActiveStep: () => {},
  steps: {
    STEP_CHOOSE: STEP_CHOOSE,
    STEP_CONTACT: STEP_CONTACT,
  },
  planType: Personalized,
  setPlanType: () => {},
  types: {
    TYPE_SoloShop: SoloShop,
    TYPE_Marketplace: Marketplace,
    TYPE_Personalized: Personalized,
  },
});

export function usePlan() {
  return useContext(PlanContext);
}

export function PlanProvider({ children }) {
  const [activeStep, setActiveStep] = useState(STEP_CHOOSE);
  const [planType, setPlanType] = useState(Personalized);

  return (
    <PlanContext.Provider
      value={{
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        steps: {
          STEP_CHOOSE: STEP_CHOOSE,
          STEP_CONTACT: STEP_CONTACT,
        },
        planType: planType,
        setPlanType: setPlanType,
        types: {
          TYPE_SoloShop: SoloShop,
          TYPE_Marketplace: Marketplace,
          TYPE_Personalized: Personalized,
        },
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}
