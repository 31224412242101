const replacers = {
  russian: {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'io',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'sht',
    ъ: 'a',
    ы: 'i',
    ь: 'y',
    э: 'e',
    ю: 'yu',
    я: 'ya',
  },

  armenian: {
    ա: 'a',
    ի: 'i',
    յ: 'y',
    տ: 't',
    բ: 'b',
    լ: 'l',
    ն: 'n',
    ր: 'r',
    գ: 'g',
    խ: 'x',
    շ: 'sh',
    ց: 'c',
    դ: 'd',
    ծ: 'c',
    ո: 'o',
    ւ: 'w',
    ե: 'e',
    կ: 'k',
    չ: 'ch',
    փ: 'p',
    զ: 'z',
    հ: 'h',
    պ: 'p',
    ք: 'q',
    է: 'e',
    ձ: 'd',
    ջ: 'j',
    օ: 'o',
    ը: 'y',
    ղ: 'gh',
    ռ: 'r',
    ֆ: 'f',
    թ: 't',
    ճ: 'tw',
    ս: 's',
    ու: 'u',
    ժ: 'jh',
    մ: 'm',
    վ: 'v',
    և: 'and',
  },

  german: {
    à: 'a',
    á: 'a',
    ä: 'a',
    â: 'a',
    è: 'e',
    é: 'e',
    ë: 'e',
    ê: 'e',
    ì: 'i',
    í: 'i',
    ï: 'i',
    î: 'i',
    ò: 'o',
    ó: 'o',
    ö: 'o',
    ô: 'o',
    ù: 'u',
    ú: 'u',
    ü: 'u',
    û: 'u',
    ñ: 'n',
    ç: 'c',
  },
};

function slugifyLang(type, text) {
  text = text.toLowerCase().trim();
  for (const key of Object.keys(replacers[type])) {
    const value = replacers[type][key];
    text = text.replace(new RegExp(key, 'g'), value);
  }

  return text
    .replace(/\s-\s/g, '-')
    .replace(/\s_\s/g, '-')
    .replace(/\s/g, '-')
    .replace(
      // eslint-disable-next-line no-useless-escape
      /[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\-\~\`\{\}\[\]\:\;\"\'\-\<\>\?\,\./]/g,
      '-'
    );
}

export function slugifyRussian(text) {
  return slugifyLang('russian', text);
}

export function slugifyArmenian(text) {
  return slugifyLang('armenian', text);
}

export function slugifyGerman(text) {
  return slugifyLang('german', text);
}

export function slugify(text) {
  const langs = Object.keys(replacers);
  for (const lang of langs) {
    text = slugifyLang(lang, text);
  }

  return text;
}

export function slugifyTitle(title, joiner = '-') {
  return slugify(title.trim().toLowerCase())
    .replace(/-+/g, joiner)
    .replace(/^-|-$/g, '')
    .replace(/^_|_$/g, '');
}
