import React, { useEffect, useState } from 'react';
import { HStack, Button, Input } from '@chakra-ui/react';
import useValidCounter from '../useValidCounter';

const buttonCommonStyle = {
  bg: 'rgba(43, 43, 43, 0.06)',
  borderRadius: 8,
  width: '48px',
  height: '48px',
  color: 'grey.900',
  fontSize: '2xl',
  _hover: {
    bg: 'rgba(147, 100, 177, 0.1)',
  },
};

function Variant2({ count, minValue, maxValue, onChange }) {
  const [inputValue, setInputValue] = useState(count);

  const {
    count: currentCount,
    canIncrement,
    canDecrement,
    increment,
    decrement,
    setCustomCount,
  } = useValidCounter({
    count: count,
    minValue: minValue,
    maxValue: maxValue,
    onChange: onChange,
  });

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value === '' || /^[0-9]*$/.test(value)) {
      setInputValue(value);
    }
  };

  const handleBlur = () => {
    const numericValue = parseInt(inputValue, 10);

    if (
      !isNaN(numericValue) &&
      numericValue >= minValue &&
      numericValue <= maxValue
    ) {
      setCustomCount(numericValue);
    } else {
      setInputValue(currentCount);
    }
  };

  useEffect(() => {
    setInputValue(currentCount);
  }, [currentCount]);

  return (
    <HStack spacing={2} alignItems="center" w="100%">
      <Button
        {...buttonCommonStyle}
        onClick={decrement}
        isDisabled={!canDecrement}
      >
        -
      </Button>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        textAlign="center"
        width="80px"
        height="48px"
        borderRadius={8}
        border="1px solid rgba(0, 0, 0, 0.1)"
        bg="rgba(43, 43, 43, 0.06)"
        color="black.50"
        fontSize="lg"
        p={1}
      />
      <Button
        {...buttonCommonStyle}
        onClick={increment}
        isDisabled={!canIncrement}
      >
        +
      </Button>
    </HStack>
  );
}

export default Variant2;
