import appBranding from '@happylife-a/branding';
import { locales as availableLocales } from './locales';

let overrideTranslationsMap = {};
export function setOverrideContent(newOverrideTranslationsMap) {
  overrideTranslationsMap = newOverrideTranslationsMap;
  mergeTranslationsMap(overrideTranslationsMap);
}

let translationsMap = {};
export function mergeTranslationsMap(newTranslationsMap) {
  const newlyMergedTranslations = {};

  const translationsToMerge = [
    translationsMap,
    newTranslationsMap,
    overrideTranslationsMap,
  ];

  for (const translationToMerge of translationsToMerge) {
    for (const locale in translationToMerge) {
      newlyMergedTranslations[locale] = newlyMergedTranslations[locale] || {};
      for (const key in translationToMerge[locale]) {
        newlyMergedTranslations[locale][key] = translationToMerge[locale][key];
      }
    }
  }

  translationsMap = newlyMergedTranslations;
}

function getTranslationByLocale(translationKey, withLocale) {
  try {
    return translationsMap[withLocale][translationKey] || translationKey;
  } catch {
    return translationKey;
  }
}

function injectDataToTranslatedText(tenantIdentifier, translated, data = {}) {
  if (typeof data === 'object') {
    for (const [key, value] of Object.entries(data)) {
      const pattern = new RegExp('{{' + key + '}}', 'g');
      translated = translated.replace(pattern, value);
    }
  }

  const appNameField = appBranding.fields.brand.AppBranding.AppName;
  const brandEnv = appBranding.configs.environments;

  const appBrandName = tenantIdentifier
    ? brandEnv.getFieldFor(tenantIdentifier, appNameField)
    : brandEnv.getField(appNameField);

  return translated?.replace(
    /\{\{AppBrandName\}\}/g,
    appBrandName || '{{AppBrandName}}'
  );
}

export function translate(tenantIdentifier, locale, translationKey, data = {}) {
  let result = getTranslationByLocale(translationKey, locale);

  // @INFO: in case of translation not found in provided locale we have
  //        to find it in another locales.
  if (result === translationKey) {
    for (const possibleLocale of availableLocales) {
      const resultInAnotherLocale = getTranslationByLocale(
        translationKey,
        possibleLocale
      );

      if (resultInAnotherLocale) {
        result = resultInAnotherLocale;
        break;
      }
    }
  }

  return injectDataToTranslatedText(tenantIdentifier, result, data);
}
