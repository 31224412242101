import React, { useMemo } from 'react';
import appBranding from '@happylife-a/branding';
import webCore from '@happylife-a/web-core';
import {
  ScreenSizeProvider,
  AppLocaleProvider,
  ModalProvider,
  OverlayProvider,
  GoogleApiProvider,
  SentryProvider,
} from '../../contexts';
import { StorageDriver } from '../../drivers';
import localModals from '../../modals';
import { theme } from '../../theme';
import NetworkProvider from '../NetworkProvider';
import PreLoadingProvider from '../PreLoadingProvider';
import useThemeColor from './useThemeColor';

const googleApiKey = appBranding.configs.environments.getField(
  appBranding.fields.env.Map.GoogleMap.Client.ApiKey
);

webCore.helpers.storage.setDriver(StorageDriver);

const apis = webCore.httpClient.apis;
webCore.httpClient.configure({
  [apis.API_CORE]: import.meta.env.VITE_API_CORE_ENDPOINT,
  [apis.API_NOTIFICATION]: import.meta.env.VITE_API_NOTIFICATION_ENDPOINT,
  [apis.API_MESSAGING]: import.meta.env.VITE_API_MESSAGING_ENDPOINT,
});

function HappyLifeWebProvider({
  children,
  initialLocale,
  translations: providedTranslations,
  modals: providedModals = {},
  htmlColor = theme.colors.primary[100],
  sentry,
}) {
  const mergedTranslations = useMemo(() => {
    return Object.keys(providedTranslations).reduce((acc, locale) => {
      acc[locale] = {
        ...providedTranslations[locale],
        // merge translations ...
      };
      return acc;
    }, {});
  }, [providedTranslations]);

  const mergedModals = useMemo(
    () => ({ ...localModals, ...providedModals }),
    [providedModals]
  );

  useThemeColor(
    typeof htmlColor !== 'string'
      ? { startColor: htmlColor.start, endColor: htmlColor.end }
      : { startColor: htmlColor, endColor: htmlColor }
  );

  return (
    <ScreenSizeProvider>
      <AppLocaleProvider
        initialLocale={initialLocale}
        translations={mergedTranslations}
      >
        <GoogleApiProvider apiKey={googleApiKey}>
          <ModalProvider modals={mergedModals}>
            <OverlayProvider>
              <webCore.contexts.CategoryProvider>
                <PreLoadingProvider>
                  <NetworkProvider>
                    <SentryProvider
                      environment={sentry?.environment}
                      dsn={sentry?.dsn}
                    >
                      <>{children}</>
                    </SentryProvider>
                  </NetworkProvider>
                </PreLoadingProvider>
              </webCore.contexts.CategoryProvider>
            </OverlayProvider>
          </ModalProvider>
        </GoogleApiProvider>
      </AppLocaleProvider>
    </ScreenSizeProvider>
  );
}

export default HappyLifeWebProvider;
